import { createContext, useContext } from 'react'

export type UIOptions = {
  hasRevampedSidebars: boolean
}

export const UIOptionsContext = createContext<UIOptions | undefined>(undefined)

export function useUIOptions() {
  return useContext(UIOptionsContext)
}

export function useUIOption<TName extends keyof UIOptions>(
  name: TName
): UIOptions[TName] | undefined {
  const ctx = useUIOptions()
  return ctx ? ctx[name] : undefined
}
