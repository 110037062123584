function jsonClone<T>(obj: T): T {
  if (obj == null) return obj
  return JSON.parse(JSON.stringify(obj)) as T
}

export function cloneDeep<T>(obj: T): T {
  if (typeof structuredClone === 'undefined') {
    return jsonClone(obj)
  }
  try {
    return structuredClone(obj)
  } catch {
    return jsonClone(obj)
  }
}
