import { queryKeys } from './keys'

import { defineApi, defineMutation } from '../../core'
import { type RouteTypes } from '../types'

type GetById = RouteTypes<'ProjectsController_getProject'>
export const getById = defineApi<
  GetById['request'],
  GetById['response']
>().using({
  key: (args) => queryKeys.byId(args.projectId),
  uri: (args) => `v2/projects/${args.projectId}`,
})

export const getLazyById = defineApi<
  GetById['request'],
  GetById['response']
>().using({
  key: (args) => ['lazy', ...queryKeys.byId(args.projectId)],
  uri: getById.uri,
  queryOptions: {
    staleTime: 0,
    cacheTime: 0,
  },
})

type Create = RouteTypes<'ProjectsController_createProject'>
export const create = defineMutation<
  Create['request'],
  Create['response']
>().using({
  uri: '/v2/projects',
  method: 'POST',
  body: (args) => args,
})

type ApplyDefinitionToProject =
  RouteTypes<'ProjectsController_applyDefinitionToProject'>
export const applyDefinitionToProject = defineMutation<
  ApplyDefinitionToProject['request'],
  ApplyDefinitionToProject['response']
>().using({
  uri: (args) =>
    `/v2/projects/${args.projectId}/definition/${args.projectDefinitionId}`,
  method: 'PATCH',
  body: ({ projectId, projectDefinitionId, ...args }) => args,
})

type Update = RouteTypes<'ProjectsController_updateProject'>
export const update = defineMutation<
  Update['request'],
  Update['response']
>().using({
  uri: (args) => `/v2/projects/${args.projectId}`,
  method: 'PATCH',
  body: ({ projectId, ...args }) => args,
})

type Delete = RouteTypes<'ProjectsController_deleteProject'>
export const deleteProject = defineMutation<Delete['request'], void>().using({
  uri: (args) => `/v2/projects/${args.projectId}`,
  method: 'DELETE',
  invalidate: (args) => [queryKeys.byId(args.projectId)],
})

type Complete = RouteTypes<'ProjectsController_completeProject'>
export const completeProject = defineMutation<
  Complete['request'],
  Complete['response']
>().using({
  uri: (args) => `/v2/projects/${args.projectId}/complete`,
  method: 'POST',
  body: ({ projectId, ...args }) => args,
})

type UpdateProjectStageDueDate =
  RouteTypes<'ProjectsController_updateProjectStageDueDate'>
export const updateProjectStageDueDate = defineMutation<
  UpdateProjectStageDueDate['request'],
  UpdateProjectStageDueDate['response']
>().using({
  uri: (args) =>
    `/v2/projects/${args.projectId}/stages/${args.stageDefinitionId}`,
  method: 'PATCH',
  body: ({ projectId, stageDefinitionId, ...args }) => args,
  invalidate: (args) => [queryKeys.byId(args.projectId)],
})

type ShiftProjectDates = RouteTypes<'ProjectsController_shiftProject'>
export const shiftProjectDates = defineMutation<
  ShiftProjectDates['request'],
  ShiftProjectDates['response']
>().using({
  uri: (args) => `/v2/projects/${args.projectId}/shift`,
  method: 'POST',
  body: ({ projectId, ...args }) => args,
  invalidate: (args) => [queryKeys.byId(args.projectId)],
})

type SetProjectToStage = RouteTypes<'ProjectsController_setProjectToStage'>
export const setProjectToStage = defineMutation<
  SetProjectToStage['request'],
  SetProjectToStage['response']
>().using({
  uri: (args) =>
    `/v2/projects/${args.projectId}/active-stage/${args.stageDefinitionId}`,
  method: 'PATCH',
  body: ({ projectId, stageDefinitionId, ...args }) => args,
  invalidate: (args) => [queryKeys.byId(args.projectId)],
})

type CreateFromTask = RouteTypes<'ProjectsController_createProjectFromTask'>
export const createFromTask = defineMutation<
  CreateFromTask['request'],
  CreateFromTask['response']
>().using({
  uri: '/v2/projects/from-task',
  method: 'POST',
  body: (args) => args,
})

type CompleteProjectStage =
  RouteTypes<'ProjectsController_completeProjectStage'>
export const completeProjectStage = defineMutation<
  CompleteProjectStage['request'],
  CompleteProjectStage['response']
>().using({
  uri: (args) =>
    `/v2/projects/${args.projectId}/stages/${args.stageDefinitionId}/complete`,
  method: 'POST',
  body: ({ projectId, stageDefinitionId, ...args }) => args,
  invalidate: (args) => [queryKeys.byId(args.projectId)],
})

type CancelProjectStage = RouteTypes<'ProjectsController_cancelProjectStage'>
export const cancelProjectStage = defineMutation<
  CancelProjectStage['request'],
  CancelProjectStage['response']
>().using({
  uri: (args) =>
    `/v2/projects/${args.projectId}/stages/${args.stageDefinitionId}/cancel`,
  method: 'POST',
  body: ({ projectId, stageDefinitionId, ...args }) => args,
  invalidate: (args) => [queryKeys.byId(args.projectId)],
})
