import { Expand2Outline } from '@motion/icons'

import { type ComponentProps, type CSSProperties, useState } from 'react'

import { IconButton, ImageViewerModal, Tooltip } from '../../base'

type RteImageProps = ComponentProps<'img'> & {
  src: string
  alt: string
  float?: CSSProperties['float']
  width?: CSSProperties['width']
  className?: string
  onExpand?: () => void
}

export function RteImage({
  src,
  alt,
  float,
  width,
  onExpand,
  ...rest
}: RteImageProps) {
  const [zoomed, setIsZoomed] = useState(false)
  const [initModal, setInitModal] = useState(false)

  const handleExpand = () => {
    setIsZoomed(true)
    onExpand?.()
  }

  const onImageMouseEnter = () => {
    setInitModal(true)
  }

  const onImageMouseLeave = () => {
    if (!zoomed) setInitModal(false)
  }

  const onModalClose = () => {
    setIsZoomed(false)
    setInitModal(false)
  }

  return (
    <>
      <div
        className='custom-image group/rte-image'
        style={{
          float,
          width,
        }}
        onPointerEnter={onImageMouseEnter}
        onPointerLeave={onImageMouseLeave}
      >
        <img {...rest} src={src} alt={alt} className='w-full h-auto' />
        <div className='group-hover/rte-image:opacity-100 opacity-0 duration-150 absolute bottom-1 right-1'>
          <Tooltip asChild content='Expand image'>
            <IconButton
              onClick={handleExpand}
              icon={Expand2Outline}
              sentiment='neutral'
              size='small'
            />
          </Tooltip>
        </div>
      </div>
      {Boolean(initModal) && (
        <ImageViewerModal
          src={src}
          alt={alt}
          onClose={onModalClose}
          visible={zoomed}
        />
      )}
    </>
  )
}
